@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
        url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNShampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215,
        U+E0FF, U+EFFD, U+F000;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzBampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215,
        U+E0FF, U+EFFD, U+F000;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

*:not(.svc-creator *) {
    scrollbar-color: #1f0e4130 #ffffff6b;
    scrollbar-width: thin;
    overflow-anchor: auto !important;
    outline: none;
    box-sizing: border-box;
    -webkit-touch-callout: default;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
    -o-tap-highlight-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar {
    width: 0px;
    height: 6px;
}
*::-webkit-scrollbar-track {
    background: #ffffff6b;
}
*::-webkit-scrollbar-thumb {
    background-color: #1f0e4130;
    background-clip: padding-box;
}
*::-o-scrollbar {
    width: 6px;
    height: 6px;
}
*::-o-scrollbar-track {
    background: #ffffff6b;
}
*::-o-scrollbar-thumb {
    background-color: #1f0e4130;
    background-clip: padding-box;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Noto Naskh Arabic, Space Mono, Monaco, Menlo, Consolas, monospace, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
}

ul,
ol {
    list-style: revert;
}

.svc-text,
.spg-title,
.spg-input,
.ql-editor,
.svc-side-bar .sv-string-viewer,
.sv-action-bar-item__title, 
.spg-panel__title,
.svc-switcher__title,
.sv-string-editor,
.sv-string-editor--html,
.svc-panel__placeholder,
.sv-list,
.svc-tabbed-menu-item__text,
.sv-popup__body-header,
.svc-add-new-item-button__text,
.svc-surface-placeholder__title,
.svc-survey-element-toolbar-item__title,
.svc-surface-placeholder__description {
    font-family: 'Noto Naskh Arabic' !important;
}
.ctm-svg svg {
    direction: ltr !important;
}
html[dir='rtl'] .svc-toolbox__container {
    direction: rtl !important;
}
html[dir='ltr'] .svc-toolbox__container {
    direction: ltr !important;
}
.survey-creator * {
    font-family: 'Noto Naskh Arabic' !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.sd-action-bar {
    display: flex;
    align-items: center;
    direction: ltr;
}
.sd-action-bar .sv-action:not(:last-child) .sv-action__content {
    padding-right: 0;
}
.sv-action__content {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sv-action__content > * {
    flex: 0 0 auto;
    flex-direction: row !important;
}

.svc-toolbox__category {
    align-items: flex-start !important;
}

.svc-flex-column.svc-side-bar__wrapper {
    border: none !important;
}
.sd-root_background-image {
    background-attachment: fixed;
}

.ReactModal__Overlay {
    z-index: 1000;
}

html[dir='rtl'] .svc-toolbox__item-banner {
    right: 0px;
}

.svc-logic-paneldynamic div.svc-logic-operator {
    height: inherit !important;
}

.szh-menu {
    box-shadow: none !important;
    padding: 0 !important;
    border: none !important;
    z-index: 101 !important;
}

.min-logo-gray path {
    fill: #999;
}

.slider-rail:hover div.slider_hand {
    opacity: 1;
}

.auto-height form {
    padding: 0 !important;
    height: auto !important;
}

.introjs-tooltipReferenceLayer * {
    font-family: Noto Naskh Arabic, Monaco !important;
}

.tooltipClass.introjs-tooltip {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
}
.introjs-helperLayer {
    border-radius: 0 !important;
}
.tooltipClass .introjs-bullets {
    display: none;
}
.tooltipClass .introjs-dontShowAgain input {
    padding: 23px 0px 0px 30px;
    color: #801e41;
    border-color: #e0e0e0;
    border-radius: 0px;
}
.tooltipClass .introjs-dontShowAgain label {
    margin: 0 5px;
}
.tooltipClass .introjs-dontShowAgain input:focus {
    --tw-ring-color: transparent;
}
.tooltipClass .introjs-progressbar {
    background-color: #801e41;
}
.introjs-progress {
    border-radius: 0 !important;
}
a.introjs-skipbutton {
    font-size: 10px;
    text-decoration: underline;
    color: #999;
}
.tooltipClass .introjs-tooltipbuttons {
    border-top: none;
}
.tooltipClass .introjs-tooltipbuttons .introjs-button {
    border: 1px solid transparent;
    border-radius: 0px;
    background-color: #fff;
    color: #000;
    text-transform: capitalize;
    padding: 5px 20px;
    text-shadow: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.tooltipClass .introjs-tooltipbuttons .introjs-button.introjs-nextbutton {
    background-color: #801e41;
    color: #fff;
}

.alice-carousel__stage-item {
    vertical-align: middle !important;
}

.alice-carousel__stage-item * {
    margin: auto !important;
}

.sd-input {
    font-family: unset !important;
    opacity: 1 !important;
}
.sd-item--disabled .sd-item__control-label {
    font-family: unset !important;
    opacity: 1 !important;
}

.sv-header__cell--left .sv-header__cell-content {
    left: auto !important;
}

.svc-surface-placeholder__image {
    padding: 70px 100px !important;
}
.svc-surface-placeholder__text {
    padding: 10px 0px !important;
}
.svc-surface-placeholder {
    padding: 0px 0px !important;
}
@media screen and (max-width: 600px) {
    *::-webkit-scrollbar {
        display: none;
    }
    *::-o-scrollbar {
        display: none;
    }
    * {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}

@media screen and (max-width: 768px) {
    .md_show {
        display: flex !important;
        flex-direction: column !important;
    }
    .svc-surface-placeholder__image {
        display: none !important;
    }
}

@layer components {
    .input-primary {
        @apply bg-light-200 border-none;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.css-1wa3eu0-placeholder {
    margin-left: 23px !important;
}
.sd-element__num {
    padding-inline-end: 0px !important;
}
.slider.slider-horizontal {
    @apply !w-[300px]  rtl:-left-16 ltr:left-16;
}
.tooltip-inner {
    @apply -ms-9;
}
.sv-components-row {
    @apply mt-5;
}
.custom-map-control-button {
    @apply bg-primary-600 p-2 rounded-sm text-white text-sm;
}
.sd-container-modern {
    @apply !block;
}
.sd-navigation__preview-btn {
    @apply !hidden;
}
.sd-input {
    font-family: unset !important;
}

@media print {
    @page {
        margin-top: 50px;
    }
    .page-break {
        page-break-before: always;
        /* page-break-after: always; */
    }
}
/* -webkit-fill-available */
@media (max-width: 440px) {
    .sm-fill-available {
        height: -webkit-fill-available;
    }
}
@media (min-width: 440px) {
    .sm-fill-available {
        height: -webkit-fit-content;
    }
}
.sm-fit-content {
    height: -webkit-fit-content;
}
.sv-popup__body-footer .sv-action-bar.sv-action-bar--default-size-mode {
    margin-left: unset !important;
}

body {
    --primary: #801e41;
    --primary-light: #a01f5a;
    --foreground: black;
    --primary-foreground: #ffffff;
    --secondary: #801e41;
}

[type='checkbox'] {
    border-color: #801e41;
    color: #b6216c;
}

[type='checkbox']:checked {
    border-color: #801e41;
    background-color: #801e41;
}

img.sd-logo__image {
    height: revert-layer !important;
}
.sd-btn,
.sd-element--with-frame {
    border-radius: 0 !important;
}
.sd-element__num {
    padding-inline-start: calc(var(--sd-base-padding) + var(--sv-element-add-padding-left, 0px)) !important;
    float: none !important;
    margin-inline-end: 15px
}
.svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title::after {
    content: '';
    display: inline-block !important;
    width: 0 !important;
    height: 0 !important;
    vertical-align: middle !important;
    border-left: 5px solid transparent;
    border-top: 5px solid #801e41;
    border-right: 5px solid transparent;
}
.svc-creator {
    border: 1px solid #edebe9;
}

.svc-page__content:not(.svc-page__content--new):focus,
.svc-hovered.svc-page__content:not(.svc-page__content--new),
.svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected,
.svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected,
.svc-hovered.svc-page__content--selected {
    background: var(--secondary-light, rgba(221, 216, 213, 1)) !important;
}
.svc-question__content:focus,
.svc-hovered > .svc-question__content {
    box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(221, 216, 213, 1))) !important;
}
.svc-required-action--active,
.svc-logo-image-placeholder:hover {
    background-color: #ddd8d5 !important;
}
.sd-question__required-text {
    color: #801e41 !important;
}
.sv-popup--modal>.sv-popup__container {
    width: 80% !important; /* Adjust as necessary */
    max-width: 1000px !important; /* Optional, to limit the max width */
}
.svc-question__content,
.svc-btn {
    border-radius: 0 !important;
}
.svc-page__footer {
    margin-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}
.sd-btn--action:hover {
    background-color: #a01f5a !important;
}
.spg-action-button:hover,
.spg-action-button:focus {
    background-color: #ddd8d580 !important;
}
.sd-btn--danger {
    background-color: #801e41 !important;
}
.svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled) use {
    fill: #801e41 !important;
}
.sd-input--error,
.sd-question__erbox--outside-question {
    background-color: #f4edf0 !important;
}
.sd-question__erbox--outside-question {
    color: #801e41 !important;
}
.spg-panel__content {
    overflow-x: hidden;
}
.noUi-connect {
    background: #801e41 !important;
}
.recharts-rectangle.recharts-tooltip-cursor {
    fill: #b98698;
}
.sd-element__title.sd-element__title--disabled {
    opacity: 1 !important;
}
.ql-toolbar.ql-snow {
    background-color: #fff !important;
    white-space: normal;
}
.sv-string-editor--html {
    white-space: normal !important;
}
.svc-question__content > .svc-question__dropdown-choices--wrapper {
    overflow-x: unset !important;
}
.quill .ql-container {
    /* max-height: 11rem; */
}
:lang(en) #quill-survey-desc {
    direction: ltr !important;
}
:lang(en) #quill-scale-desc {
    direction: ltr !important;
}
:lang(ar) .ql-editor {
    background-color: #fff !important;
    text-align: right !important;
    height: 11rem !important;
}
:lang(en) .ql-editor {
    background-color: #fff !important;
    text-align: left !important;
    height: 11rem !important;
}
:lang(ar) .ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: unset !important;
}

.ql-editor ol,
.ql-editor ul {
    padding-left: unset !important;
}

.sd-header__text > h3.sd-title {
    @apply justify-center !mx-auto;
}

#react-select-5-input:focus {
    --tw-ring-color: none;
}
.rmsc .dropdown-container {
    border: 0;
    box-shadow: none !important;
}
.dropdown-container {
    border-radius: 0px !important;
    height: 50px;
    display: flex;
    align-items: center;
    border: 1px solid #edebe9 !important;
    max-width: 100%;
}
.dropdown-container:focus,
.dropdown-container:focus-within {
    border: 2px solid #801e41 !important;
}
.css-d7l1ni-option {
    background-color: #f0f0f0 !important;
}
.css-17h53pk-control {
    box-shadow: none !important;
}
.css-17h53pk-control:hover {
    border-color: #f0f0f0 !important;
}
.dropdown-content {
    outline: 2px solid #801e41 !important;
    width: 100% !important;
    margin-top: 4px;
}
.spg-root-modern {
    /* min-height: 93%; */
    display: grid;
}
.panel-content {
    border-radius: 0px !important;
}
.item-renderer {
    @apply !flex gap-2;
}
/*.sd-root-modern__wrapper {
    height: 100%;
    min-height: 85vh;
}
.sd-root-modern__wrapper > form {
    height: 100%;
}
.spg-container-modern {
    height: 100%;
} */
.custom-shadow {
    @apply shadow-md; /* Apply a medium shadow */
    box-shadow: 1px -1px 10px 1px rgba(0, 0, 0, 0.1); /* Apply custom box shadow with negative vertical offset */
}
.svc-tab-designer {
    background-color: #edebe9 !important;
}
.spg-container_search .spg-root-modern {
    top: unset !important;
    margin-top: unset !important;
    overflow: unset !important;
}
.spg-search-editor_container {
    display: none !important;
}
.spg-panel__content {
    background: unset !important;
}
.rmsc {
    --rmsc-main: #801e41 !important;
}
.focus-within-border-primary-600:focus-within {
    border: 2px solid #801e41 !important;
}
#surveyDashboardContainer > .sa-visualizer__toolbar,
#surveyDashboardContainer > .sa-visualizer__footer {
    display: none;
    overflow: visible !important;
}
input:-internal-autofill-selected {
    background-color: #fff !important;
    background: none !important;
}

.col-table {
    width: 100%;
    border-collapse: collapse;
}

.col-table th,
.col-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.col-table th {
    background-color: #f2f2f2;
}

.col-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.pdf-font {
    font-family: 'Noto Naskh Arabic' !important;
}
.recharts-surface {
    margin-left: 4px;
}
.recharts-legend-wrapper {
    margin-bottom: -1.7rem;
}
/* .recharts-tooltip-wrapper {
    width: 100%;
} */
.recharts-text.recharts-cartesian-axis-tick-value > tspan {
    font-size: 12px;
}
.custom-tooltip {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #edebe9;
    padding: 10px;
    z-index: 555;
    width: 70rem;
    max-width: 15%;
    margin: auto;
    text-align: justify;
}
.custom-dist-tooltip {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #edebe9;
    padding: 10px;
    z-index: 555;
    width: 40rem;
    max-width: 30%;
    margin: auto;
    text-align: justify;
}
.custom-line-tooltip {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #edebe9;
    padding: 10px;
    z-index: 555;
    width: 70rem;
    max-width: 15%;
    margin: auto;
    text-align: justify;
}
.react-datepicker-popper {
    z-index: 50 !important;
}
.recharts-legend-item.legend-item-0 {
    display: none !important;
}

div[role='option'][aria-selected='true'] {
    color: #000 !important;
    background-color: #d9d9d9 !important;
}

.page-break:nth-child(4) {
    /* page-break-inside: always !important; */
    page-break-after: always !important;
}
.apexcharts-tooltip-boxplot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: white;
    padding: 5px;
    border: 1px solid #ccc;
}
.tooltip-part {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tooltip-part span {
    font-size: 12px;
    margin-top: 2px;
}

.ql-align-right {
    text-align: right;
}

.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: left;
}
.sv-list__item-body.sd-list__item-body {
    text-align: start;
}
.DatePicker {
    width: 100%;
}
.nav-button {
    margin-left: 0;
    margin-right: auto;
}
.nav-input {
    color: #ffffff;
    background-color: #ff9814;
}
.nav-input:hover {
    background-color: #f28a05;
}
.sd-btn.nav-input {
    background-color: #801e41;
    color: white;
}
.sd-btn.nav-input:hover {
    color: #801e41;
}
.sd-table__cell--row-text {
    text-align: unset !important;
}
.splide__arrow svg {
    fill: #801e41 !important;
}
.splide__pagination__page {
    background-color: #fff !important;
}
.splide__pagination__page.is-active {
    background-color: #801e41 !important;
}

div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    direction: ltr !important;
}
div.MuiPickersToolbar-root.MuiDateTimePickerToolbar-root.MuiPickersLayout-toolbar {
    direction: rtl !important;
}

.MuiPickersLayout-contentWrapper .MuiPickersLayout-tabs button.Mui-selected {
    color: #801e41 !important;
}

.MuiPickersLayout-contentWrapper button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: #801e41 !important;
}
.MuiPickersLayout-contentWrapper button.MuiButtonBase-root.MuiPickersDay-root:hover {
    background-color: #801e4013 !important;
}

.MuiPickersLayout-contentWrapper .MuiTabs-indicator {
    background-color: #801e41 !important;
}

.MuiClock-clock .MuiClock-pin {
    background-color: #801e41 !important;
}
.MuiClock-clock .MuiClockPointer-root {
    background-color: #801e41 !important;
}

.MuiClock-clock .MuiClockPointer-root .MuiClockPointer-thumb {
    border-color: #801e41 !important;
    background-color: #801e41 !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing button.MuiButtonBase-root {
    color: #801e41 !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing button.MuiButtonBase-root:hover {
    background-color: #801e4013 !important;
}
.sv_progress-toc .sv-list__item.sv-list__item--selected .sv-list__item-body {
    color: white !important;
}